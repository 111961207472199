import { Navigate, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { ThemedLayout } from '../layout';
import { Routes as AppRoutes } from './Routes';
import { Permissions } from '../services';
import { OrbitalProgress } from '../components/mui';
import PermissionRoute from './PermissionRoute';

const Welcome = React.lazy(() => import('../pages/Welcome'));
const AccessDenied = React.lazy(() => import('../pages/AccessDenied'));
const NotFound = React.lazy(() => import('../pages/NotFound'));

const Member = React.lazy(() => import('../pages/cafe/member/Member'));
const MemberAll = React.lazy(() => import('../pages/cafe/member/MemberAll'));
const MemberEarn = React.lazy(() => import('../pages/cafe/member/MemberEarn'));
const MemberGeneral = React.lazy(() => import('../pages/cafe/member/MemberGeneral'));
const MemberEarnPlus = React.lazy(() => import('../pages/cafe/member/MemberEarnPlus'));
const MemberRisk = React.lazy(() => import('../pages/cafe/member/MemberRisk'));
const MemberSurveys = React.lazy(() => import('../pages/cafe/member/MemberSurveys'));
const MemberStreak = React.lazy(() => import('../pages/cafe/streaks/MemberStreak'));
const Members = React.lazy(() => import('../pages/cafe/Members'));
const Array = React.lazy(() => import('../pages/cafe/array/Array'));
const BatchWorkflow = React.lazy(() => import('../pages/cafe/array/BatchWorkflow'));

const DailyExecutiveReport = React.lazy(() => import('../pages/lattice/DailyExecutiveReport'));
const Reports = React.lazy(() => import('../pages/lattice/Reports'));
const Referrals = React.lazy(() => import('../pages/lattice/referrals/Referrals'));
const ReferralsList = React.lazy(() => import('../pages/lattice/referrals/ReferralsList'));
const ReferralsExports = React.lazy(() => import('../pages/lattice/referrals/ReferralsExports'));

const AchReturnsReport = React.lazy(() => import('../pages/riskops/AchReturnsReport'));
const Disputes = React.lazy(() => import('../pages/riskops/Disputes'));
const FraudEvents = React.lazy(() => import('../pages/riskops/FraudEvents'));
const NegativeBalancesReport = React.lazy(() => import('../pages/riskops/NegativeBalancesReport'));

const Booster = React.lazy(() => import('../pages/marketing/Booster'));
const Boosters = React.lazy(() => import('../pages/marketing/Boosters'));
const Earn = React.lazy(() => import('../pages/marketing/Earn'));
const Promotions = React.lazy(() => import('../pages/marketing/promotions/Promotions'));
const OngoingPromotions = React.lazy(() => import('../pages/marketing/promotions/OngoingPromotions'));
const ArchivedPromotions = React.lazy(() => import('../pages/marketing/promotions/ArchivedPromotions'));
const Promotion = React.lazy(() => import('../pages/marketing/promotions/Promotion'));

const Experiments = React.lazy(() => import('../pages/labs/Experiments'));
const ExperimentsArchived = React.lazy(() => import('../pages/labs/experiments/Archived'));
const ExperimentsOngoing = React.lazy(() => import('../pages/labs/experiments/Ongoing'));
const ExperimentsSnapshots = React.lazy(() => import('../pages/labs/experiments/Snapshots'));

const Agent = React.lazy(() => import('../pages/shipyard/Agent'));
const AgentActivity = React.lazy(() => import('../pages/shipyard/agent/Activity'));
const AgentPermissions = React.lazy(() => import('../pages/shipyard/agent/Permissions'));
const AgentSettings = React.lazy(() => import('../pages/shipyard/agent/Settings'));

const DiamondDrops = React.lazy(() => import('../pages/shipyard/diamondDrops/DiamondDrops'));

const Agents = React.lazy(() => import('../pages/shipyard/Agents'));
const AgentsRoles = React.lazy(() => import('../pages/shipyard/agents/Roles'));
const AgentsActivity = React.lazy(() => import('../pages/shipyard/agents/Activity'));
const AgentsAudit = React.lazy(() => import('../pages/shipyard/agents/Audit'));

const OrgAccounts = React.lazy(() => import('../pages/shipyard/OrgAccounts'));
const SysCon = React.lazy(() => import('../pages/shipyard/SysCon'));
const Partnerships = React.lazy(() => import('../pages/shipyard/partnerships/Partnerships'));
const Affiliate = React.lazy(() => import('../pages/shipyard/partnerships/Affiliate'));
const AffiliateWizard = React.lazy(() => import('../pages/affiliates/AffiliateWizard'));
const AffiliateWizardGeneral = React.lazy(() => import('../pages/affiliates/wizard/AffiliateWizardGeneral'));
const AffiliateWizardAssets = React.lazy(() => import('../pages/affiliates/wizard/AffiliateWizardAssets'));
const AffiliateWizardTiers = React.lazy(() => import('../pages/affiliates/wizard/AffiliateWizardTiers'));
const AffiliateWizardPostbackUrl = React.lazy(() => import('../pages/affiliates/wizard/AffiliateWizardPostbackUrl'));
const AffiliateWizardCheckoutScreen = React.lazy(
   () => import('../pages/affiliates/wizard/AffiliateWizardCheckoutScreen'),
);
const AffiliateWizardCheckoutScreenFeatures = React.lazy(
   () => import('../pages/affiliates/wizard/AffiliateWizardCheckoutScreenFeatures'),
);
const Affiliates = React.lazy(() => import('../pages/shipyard/partnerships/Affiliates'));
const EarnFeedWaterfall = React.lazy(() => import('../pages/shipyard/partnerships/EarnFeedWaterfall'));
const PartnerOfferGroups = React.lazy(() => import('../pages/shipyard/partnerships/PartnerOfferGroups'));

const BridgeBucks = React.lazy(() => import('../pages/shipyard/bridgeBucks/BridgeBucks'));
const TilloMerchant = React.lazy(() => import('../pages/shipyard/tillo/TilloMerchant'));
const TilloMerchants = React.lazy(() => import('../pages/shipyard/tillo/TilloMerchants'));

const ActionEvents = React.lazy(() => import('../pages/dataDepot/ActionEvents'));
const GameEvents = React.lazy(() => import('../pages/dataDepot/GameEvents'));
const SurveyEvents = React.lazy(() => import('../pages/dataDepot/SurveyEvents'));
const AdEvents = React.lazy(() => import('../pages/dataDepot/AdEvents'));
const AffiliateEvents = React.lazy(() => import('../pages/dataDepot/AffiliateEvents'));
const EarnTaskReport = React.lazy(() => import('../pages/dataDepot/EarnTaskReport'));

const SailfishQualifications = React.lazy(() => import('../pages/sailfish/SailfishQualifications'));
const SailfishQualificationsAnswers = React.lazy(() => import('../pages/sailfish/SailfishQualificationsAnswers'));
const SailfishSurveys = React.lazy(() => import('../pages/sailfish/SailfishSurveys'));
const SailfishSurvey = React.lazy(() => import('../pages/sailfish/SailfishSurvey'));
const SailfishSurveysGeneral = React.lazy(() => import('../pages/sailfish/SailfishSurveysGeneral'));
const SailfishSurveysRedirects = React.lazy(() => import('../pages/sailfish/SailfishSurveysRedirects'));
const SailfishYieldManagement = React.lazy(() => import('../pages/sailfish/SailfishYieldManagement'));
const SailfishQuestions = React.lazy(() => import('../pages/sailfish/SailfishQuestions'));
const SailfishQuestion = React.lazy(() => import('../pages/sailfish/SailfishQuestion'));
const SailfishRewards = React.lazy(() => import('../pages/sailfish/SailfishRewards'));
const SailfishRewardsMain = React.lazy(() => import('../pages/sailfish/SailfishRewardsMain'));
const SailfishCompletes = React.lazy(() => import('../pages/sailfish/SailfishCompletes'));

const ArcadeGame = React.lazy(() => import('../pages/arcade/ArcadeGame'));
const BesitosGame = React.lazy(() => import('../pages/arcade/BesitosGame'));
const ArcadeGames = React.lazy(() => import('../pages/arcade/ArcadeGames'));
const ArcadeWizard = React.lazy(() => import('../pages/arcade/ArcadeWizard'));
const ArcadeWizardGeneral = React.lazy(() => import('../pages/arcade/wizard/ArcadeWizardGeneral'));
const ArcadeWizardAssets = React.lazy(() => import('../pages/arcade/wizard/ArcadeWizardAssets'));
const ArcadeWizardTags = React.lazy(() => import('../pages/arcade/wizard/ArcadeWizardTags'));
const ArcadeWizardLevels = React.lazy(() => import('../pages/arcade/wizard/ArcadeWizardLevels'));
const ArcadeEvents = React.lazy(() => import('../pages/arcade/Events'));
const ArcadePortal = React.lazy(() => import('../pages/arcade/ArcadePortal'));
const ArcadeUserGames = React.lazy(() => import('../pages/arcade/ArcadeUserGames'));
const ArcadeGamesWaterfall = React.lazy(() => import('../pages/arcade/ArcadeGamesWaterfall'));
const ArcadeFeaturedWaterfall = React.lazy(() => import('../pages/arcade/ArcadeFeaturedWaterfall'));
const BesitosGames = React.lazy(() => import('../pages/arcade/BesitosGames'));
const YieldManagement = React.lazy(() => import('../pages/arcade/YieldManagement'));

function AuthRoutes(): JSX.Element {
   function withPermissions(permissions: Permissions[], component: any) {
      return <PermissionRoute permissions={permissions}>{component}</PermissionRoute>;
   }

   function renderRoutes() {
      return (
         <Suspense fallback={<OrbitalProgress overlay />}>
            <Routes>
               <Route path="*" element={<NotFound />} />
               <Route path={AppRoutes.WELCOME} element={<Welcome />} />
               <Route path={AppRoutes.ROOT} element={<Navigate to={AppRoutes.WELCOME} />} />
               <Route path={AppRoutes.ACCESS_DENIED} element={<AccessDenied />} />
               <Route path={AppRoutes.NOT_FOUND} element={<NotFound />} />

               <Route
                  path={AppRoutes.CAFE_ARRAY}
                  element={withPermissions([Permissions.VIEW_ARRAY, Permissions.MANAGE_ARRAY], <Array />)}
               />
               <Route
                  path={AppRoutes.CAFE_ARRAY_WORKFLOW}
                  element={withPermissions([Permissions.VIEW_ARRAY], <BatchWorkflow />)}
               />
               <Route
                  path={AppRoutes.CAFE_MEMBERS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <Members />)}
               />
               <Route path={AppRoutes.CAFE_MEMBER} element={withPermissions([Permissions.VIEW_MEMBERS], <Member />)}>
                  <Route path="" element={<MemberGeneral />} />
                  <Route path="general" element={<MemberGeneral />} />
                  <Route path="earn" element={<MemberEarn />} />
                  <Route path="earn_plus" element={<MemberEarnPlus />} />
                  <Route path="risk" element={<MemberRisk />} />
                  <Route path="surveys" element={<MemberSurveys />} />
                  <Route path="all" element={<MemberAll />} />
               </Route>

               <Route
                  path={AppRoutes.SAILFISH_QUALIFICATIONS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <SailfishQualifications />)}
               >
                  <Route path="" element={<SailfishQuestions />} />
                  <Route path="answers" element={<SailfishQualificationsAnswers />} />
               </Route>
               <Route
                  path={AppRoutes.SAILFISH_QUALIFICATIONS_QUESTION}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <SailfishQuestion />)}
               />
               <Route
                  path={AppRoutes.SAILFISH_SURVEYS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <SailfishSurveys />)}
               >
                  <Route path="" element={<SailfishSurveysGeneral />} />
                  <Route path="yield_management" element={<SailfishYieldManagement />} />
               </Route>

               <Route
                  path={AppRoutes.SAILFISH_REWARDS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <SailfishRewardsMain />)}
               >
                  <Route path="" element={<SailfishRewards />} />
                  <Route path="redirects" element={<SailfishSurveysRedirects />} />
                  <Route path="completes" element={<SailfishCompletes />} />
               </Route>
               <Route
                  path={AppRoutes.SAILFISH_SURVEY}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <SailfishSurvey />)}
               />

               <Route
                  path={AppRoutes.SHIPYARD_ARCADE_GAME}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <ArcadeGame />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_ARCADE_BESITOS_GAME}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <BesitosGame />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_ARCADE}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <ArcadePortal />)}
               >
                  <Route path="" element={<ArcadeGames />} />
                  <Route path="games" element={<ArcadeGames />} />
                  <Route path="events" element={<ArcadeEvents />} />
                  <Route path="user_games" element={<ArcadeUserGames />} />
                  <Route path="games_waterfall" element={<ArcadeGamesWaterfall />} />
                  <Route path="featured_waterfall" element={<ArcadeFeaturedWaterfall />} />
                  <Route path="besitos" element={<BesitosGames />} />
                  <Route path="yield_management" element={<YieldManagement />} />
               </Route>
               <Route
                  path={AppRoutes.SHIPYARD_ARCADE_GAME_WIZARD}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <ArcadeWizard />)}
               >
                  <Route path="" element={<ArcadeWizardGeneral />} />
                  <Route path="general" element={<ArcadeWizardGeneral />} />
                  <Route path="assets" element={<ArcadeWizardAssets />} />
                  <Route path="tags" element={<ArcadeWizardTags />} />
                  <Route path="levels" element={<ArcadeWizardLevels />} />
               </Route>

               <Route path={AppRoutes.CAFE_STREAK} element={<MemberStreak />} />

               <Route
                  path={AppRoutes.ADMIN_ORG_ACCOUNTS}
                  element={withPermissions([Permissions.MANAGE_PRODUCT], <OrgAccounts />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_SYS_CON}
                  element={withPermissions([Permissions.MANAGE_PRODUCT, Permissions.VIEW_MEMBERS], <SysCon />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_DIAMOND_DROPS}
                  element={withPermissions([Permissions.MANAGE_PRODUCT, Permissions.VIEW_MEMBERS], <DiamondDrops />)}
               />

               <Route path={AppRoutes.ADMIN_AGENTS} element={withPermissions([Permissions.MANAGE_AGENTS], <Agents />)}>
                  <Route path="" element={<AgentsRoles />} />
                  <Route path="roles" element={<AgentsRoles />} />
                  <Route path="activity" element={<AgentsActivity />} />
                  <Route path="audit" element={<AgentsAudit />} />
               </Route>
               <Route path={AppRoutes.ADMIN_AGENT} element={<Agent />}>
                  <Route path="" element={<AgentPermissions />} />
                  <Route path="permissions" element={<AgentPermissions />} />
                  <Route path="activity" element={<AgentActivity />} />
                  <Route path="settings" element={<AgentSettings />} />
               </Route>
               <Route
                  path={AppRoutes.DATA_DEPOT_BRIDGE_BUCKS}
                  element={withPermissions([Permissions.MANAGE_PRODUCT, Permissions.VIEW_MEMBERS], <BridgeBucks />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_PARTNERSHIPS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <Partnerships />)}
               >
                  <Route path="" element={<Affiliates />} />
                  <Route path="waterfall" element={<EarnFeedWaterfall />} />
                  <Route path="groups" element={<PartnerOfferGroups />} />
               </Route>
               <Route
                  path={AppRoutes.SHIPYARD_PARTNERSHIPS_AFFILIATE}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <Affiliate />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <AffiliateWizard />)}
               >
                  <Route path="" element={<AffiliateWizardGeneral />} />
                  <Route path="general" element={<AffiliateWizardGeneral />} />
                  <Route path="postback_url" element={<AffiliateWizardPostbackUrl />} />
                  <Route path="tiers" element={<AffiliateWizardTiers />} />
                  <Route path="assets" element={<AffiliateWizardAssets />} />
                  <Route path="checkout_screen" element={<AffiliateWizardCheckoutScreen />} />
                  <Route path="checkout_screen_features" element={<AffiliateWizardCheckoutScreenFeatures />} />
               </Route>
               <Route
                  path={AppRoutes.SHIPYARD_BRIDGE_TILLO_MERCHANTS}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <TilloMerchants />)}
               />
               <Route
                  path={AppRoutes.SHIPYARD_BRIDGE_TILLO_MERCHANT}
                  element={withPermissions([Permissions.VIEW_MEMBERS], <TilloMerchant />)}
               />

               <Route
                  path={AppRoutes.LABS_EXPERIMENTS}
                  element={withPermissions([Permissions.VIEW_LABS, Permissions.MANAGE_LABS], <Experiments />)}
               >
                  <Route path="" element={<ExperimentsOngoing />} />
                  <Route path="ongoing" element={<ExperimentsOngoing />} />
                  <Route path="archived" element={<ExperimentsArchived />} />
                  <Route path="snapshots" element={<ExperimentsSnapshots />} />
               </Route>

               <Route
                  path={AppRoutes.MARKETING_EARN}
                  element={withPermissions([Permissions.MANAGE_MARKETING], <Earn />)}
               />
               <Route
                  path={AppRoutes.MARKETING_PROMOTIONS}
                  element={withPermissions([Permissions.MANAGE_MARKETING], <Promotions />)}
               >
                  <Route path="" element={<OngoingPromotions />} />
                  <Route path="ongoing" element={<OngoingPromotions />} />
                  <Route path="archived" element={<ArchivedPromotions />} />
               </Route>
               <Route
                  path={AppRoutes.MARKETING_PROMOTION}
                  element={withPermissions([Permissions.MANAGE_MARKETING], <Promotion />)}
               />
               <Route
                  path={AppRoutes.MARKETING_BOOSTERS}
                  element={withPermissions([Permissions.MANAGE_MARKETING], <Boosters />)}
               />
               <Route
                  path={AppRoutes.MARKETING_BOOSTER}
                  element={withPermissions([Permissions.MANAGE_MARKETING], <Booster />)}
               />

               <Route
                  path={AppRoutes.LATTICE_REPORTS}
                  element={withPermissions([Permissions.VIEW_REPORTS], <Reports />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_EXECUTIVE_RECENT}
                  element={withPermissions([Permissions.VIEW_REPORTS], <DailyExecutiveReport />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_ACTION_EVENTS}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <ActionEvents />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_GAME_EVENTS}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <GameEvents />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_SURVEY_EVENTS}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <SurveyEvents />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_AD_EVENTS}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <AdEvents />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_AFFILIATE_EVENTS}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <AffiliateEvents />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_EARN_TASK_REPORT}
                  element={withPermissions([Permissions.VIEW_REPORTS, Permissions.VIEW_MEMBERS], <EarnTaskReport />)}
               />
               <Route
                  path={AppRoutes.LATTICE_DAILY_EXECUTIVE_REPORT}
                  element={withPermissions([Permissions.VIEW_REPORTS], <DailyExecutiveReport />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_REFERRALS}
                  element={withPermissions([Permissions.VIEW_REPORTS], <Referrals />)}
               >
                  <Route path="" element={<ReferralsList />} />
                  <Route path="list" element={<ReferralsList />} />
                  <Route path="exports" element={withPermissions([Permissions.EXPORT_REPORTS], <ReferralsExports />)} />
               </Route>
               <Route
                  path={AppRoutes.RISKOPS_NEGATIVE_BALANCES_RECENT}
                  element={withPermissions([Permissions.VIEW_REPORTS], <NegativeBalancesReport />)}
               />
               <Route
                  path={AppRoutes.RISKOPS_NEGATIVE_BALANCES_REPORT}
                  element={withPermissions([Permissions.VIEW_REPORTS], <NegativeBalancesReport />)}
               />
               <Route
                  path={AppRoutes.RISKOPS_DISPUTES}
                  element={withPermissions([Permissions.MANAGE_RISK_OPS], <Disputes />)}
               />
               <Route
                  path={AppRoutes.DATA_DEPOT_FRAUD_EVENTS}
                  element={withPermissions([Permissions.MANAGE_RISK_OPS], <FraudEvents />)}
               />
               <Route
                  path={AppRoutes.RISKOPS_ACH_RETURNS_RECENT}
                  element={withPermissions([Permissions.VIEW_REPORTS], <AchReturnsReport />)}
               />
            </Routes>
         </Suspense>
      );
   }

   return <ThemedLayout>{renderRoutes()}</ThemedLayout>;
}

export default AuthRoutes;
export { AuthRoutes };
